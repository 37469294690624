import { Inject, Injectable } from '@angular/core';
import Dexie from 'dexie';
import { Feature, FeatureCollection } from 'geojson';

import { Patch } from '@priva/next-model';

import { DEXIE_CONFIG, DexieConfiguration } from '../local-storage/provider/dexie.config';

@Injectable({
    providedIn: 'root',
})
export class FloorplanLocalStorageService {
    constructor(
        private db: Dexie,
        @Inject(DEXIE_CONFIG) private dexieConfiguration: DexieConfiguration,
    ) {
        this.initDB();
    }

    /* istanbul ignore next wvk, simple return */
    public async getFloorplanZone(solutionId: string, zoneId: string): Promise<Feature> {
        return this.db.table('floorplanZone').where({ solutionId, id: zoneId }).first();
    }

    /* istanbul ignore next wvk, hard to test */
    public async getFloorplanZoneZones(solutionId: string, siteId: string): Promise<Feature[]> {
        return this.db
            .table('floorplanZone')
            .where({ solutionId })
            .filter((zone: Feature) => zone.properties.siteId && zone.properties.siteId === siteId)
            .toArray();
    }

    /* istanbul ignore next wvk, simple return */
    public async getFloorplanElements(solutionId: string): Promise<Feature[]> {
        return this.db.table('floorplanElement').where({ solutionId }).toArray();
    }

    /* istanbul ignore next wvk, simple return */
    public async getFloorplanElement(solutionId: string, elementId: string): Promise<Feature> {
        return this.db.table('floorplanElement').where({ solutionId, id: elementId }).first();
    }

    /* istanbul ignore next wvk, hard to test */
    public async clear(solutionId: string): Promise<void> {
        await this.delete('floorplanZone', solutionId);
        await this.delete('floorplanElement', solutionId);
        await this.delete('floorplanImage', solutionId);
    }

    /* istanbul ignore next wvk, simple return */
    public async putZone(solutionId: string, zone: Feature): Promise<any> {
        return this.db.table('floorplanZone').put({ id: zone.properties.id, solutionId, ...zone });
    }

    /* istanbul ignore next wvk, simple return */
    public async putZones(solutionId: string, siteId, zoneZones: FeatureCollection): Promise<any> {
        const features = zoneZones.features.map((zone: Feature) => ({
            id: zone.properties.id,
            solutionId,
            ...zone,
            properties: { ...zone.properties, siteId },
        }));
        return this.db.table('floorplanZone').bulkPut(features);
    }

    /* istanbul ignore next wvk, simple return */
    public async getImage(imageUrl: string): Promise<Blob> {
        const image = await this.db.table('floorplanImage').where({ imageUrl }).first();
        return image.image;
    }

    /* istanbul ignore next wvk, simple return */
    public async putImage(solutionId: string, imageUrl: string, image: Blob) {
        return this.db.table('floorplanImage').put({ imageUrl, solutionId, image });
    }

    /* istanbul ignore next wvk, simple return */
    public async putElements(solutionId: string, collection: FeatureCollection): Promise<any> {
        const features = collection.features.map((f: Feature) => ({ id: f.properties.id, solutionId, ...f }));
        return this.db.table('floorplanElement').bulkPut(features);
    }

    /* istanbul ignore next wvk, simple return */
    public async patchElement(solutionId: string, patch: Patch): Promise<any> {
        if (patch.geometry) {
            const feature = await this.getFloorplanElement(solutionId, patch.id);
            feature.geometry = patch.geometry;
            return this.db.table('floorplanElement').update({ id: patch.id, solutionId }, feature);
        }
    }

    /* istanbul ignore next wvk, simple return */
    public async delete(entity: string, solutionId: string): Promise<void> {
        await this.db.table(entity).where({ solutionId }).delete();
    }

    /* istanbul ignore next wvk, just init */
    private initDB(): void {
        // Name only the indexed columns in the store definition
        this.db.version(this.dexieConfiguration.databaseVersion).stores({
            floorplanZone: 'id, solutionId, [solutionId+id]',
            floorplanElement: 'id, solutionId, [solutionId+id]',
            floorplanImage: 'imageUrl, solutionId',
        });
    }
}
